import './styles.scss';

import PowerFlow from "../../power-flow";
import HistoryChart from "../../history-chart";

import { DateTime } from 'luxon';
import { useState, useEffect, useContext } from 'react';
import * as dat from 'dat.gui';
import PageWrapper from "../../page-wrapper";
import {ApiInterface} from "../../../api-interface";
import DebugContext from "../../../contexts/debug";
import Overlay from "../../overlay";
import logo from "../../../images/bestwatt-logo.png";

import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import WarningIcon from '@material-ui/icons/Warning';

const apiInterface = new ApiInterface();

export default function Screen(
    {
        token,
        installationOrGroup = 'installation',
        installationOrGroupId = 0,
        liveDataUpdateIntervalSeconds = 10 * 60, // 10 minutes
        recentDataUpdateIntervalSeconds = 15 * 60,
        greenLight = false
    }) {

    const [realtimeData, setRealtimeData] = useState({
        maxPowerYieldKw: 0,
        powerYieldKw: 0,
        powerUsageKw: 0,
        powerGridKw: 0,
        powerStorageKw: 0,
        storageState: 'discharging', // charging|discharging
        storageChargedPercentage: 0,
        sunPowerPercentage: 0,
        visualizeSolarPowerPercentage: true
    });

    const debugModeEnabled = useContext(DebugContext);

    const [longTermChartData, setLongTermChartData] = useState({
        firstDateTime: null,
        lastDateTime: null,
        animateChart: true,
        apiData: {}
    });

    const [recentData, setRecentData] = useState({
        firstDateTime: null,
        lastDateTime: null,
        animateChart: true,
        apiData: {}
    });

    const [realtimeDataIsLoading, setRealtimeDataIsLoading] = useState(false);
    const [realtimeDataHasError, setRealtimeDataHasError] = useState(false);

    const [recentChartIsLoading, setRecentChartIsLoading] = useState(false);
    const [recentChartHasError, setRecentChartHasError] = useState(false);

    const [longTermChartIsLoading, setLongTermChartIsLoading] = useState(false);
    const [longTermChartHasError, setLongTermChartHasError] = useState(false);

    useEffect(() => {
        if (greenLight) {
            updateLiveData();
            updateRecentData({animateChart: true});
            updateLongTermData({animateChart: true});
        }
    }, []);

    // auto update
    useEffect(() => {
        if (greenLight) {
            const liveDataUpdateInterval = window.setInterval(() => {
                if (!realtimeDataIsLoading) {
                    updateLiveData({
                        animateChart: false
                    });
                }
            }, liveDataUpdateIntervalSeconds * 1000);

            const recentDataUpdateInterval = window.setInterval(() => {
                if (!recentChartIsLoading) {
                    updateRecentData({
                        animateChart: false
                    });
                }
            }, recentDataUpdateIntervalSeconds * 1000);

            return () => {
                window.clearInterval(liveDataUpdateInterval);
                window.clearInterval(recentDataUpdateInterval);
            }
        }
    }, []);

    // gui for debug/develop purposes
    useEffect(() => {
        if (debugModeEnabled) {
            const gui = new dat.GUI();

            let outerFolder = gui.addFolder('Realtime');

            let folder = outerFolder.addFolder('Power');
            folder.open();
            folder.add(realtimeData, 'powerYieldKw', 0, 2000, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, powerYieldKw: value
                });
            });
            folder.add(realtimeData, 'powerUsageKw', 0, 2000, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, powerUsageKw: value
                });
            });
            folder.add(realtimeData, 'powerGridKw', 0, 2000, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, powerGridKw: value
                });
            });
            folder.add(realtimeData, 'powerStorageKw', -2000, 2000, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, powerStorageKw: value
                });
            });
            folder = outerFolder.addFolder('Misc.');
            folder.open();
            folder.add(realtimeData, 'maxPowerYieldKw', 0, 2000, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, maxPowerYieldKw: value
                });
            });
            folder.add(realtimeData, 'sunPowerPercentage', 0, 100, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, sunPowerPercentage: value
                });
            });
            folder.add(realtimeData, 'storageChargedPercentage', 0, 100, 1).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, storageChargedPercentage: value
                });
            });
            folder.add(realtimeData, 'storageState', ['charging', 'discharging']).onChange((value) => {
                setRealtimeData({
                    ...realtimeData, storageState: value
                });
            });
            folder.add(realtimeData, 'visualizeSolarPowerPercentage').onChange((value) => {
                setRealtimeData({
                    ...realtimeData, visualizeSolarPowerPercentage: value
                });
            });
            folder.add({
                updateData: () => {
                    updateLiveData();
                }
            }, 'updateData');

            outerFolder = gui.addFolder('Recent data');
            outerFolder.open();
            outerFolder.add({
                updateData: () => {
                    updateRecentData();
                }
            }, 'updateData');

            outerFolder = gui.addFolder('Longterm data');
            outerFolder.open();
            outerFolder.add({
                updateData: () => {
                    updateLongTermData();
                }
            }, 'updateData');

            return () => {
                gui.destroy();
            }
        }
    }, []);

    async function updateRecentData({animateChart = false} = {}) {
        setRecentChartHasError(false);
        setRecentChartIsLoading(true);

        try {
            const today = DateTime.fromObject({
                hour: 0,
                second: 0,
                millisecond: 0,
                zone: 'utc'
            });

            const firstDateTime = today.minus({
                days: 1
            });
            const lastDateTime = today.plus({
                days: 1
            });

            let path = '';
            if (installationOrGroup === 'installation') {
                path += '/Installation'
            } else if (installationOrGroup === 'installationGroup') {
                path += '/InstallationGroup'
            }
            path += '/' + installationOrGroupId + '/History/19';

            const response = await apiInterface.makeRequest({
                token: token,
                path: path,
                headers: {
                    fromdate: firstDateTime.toFormat('yyyy-M-d HH:mm:ss'),
                    thrudate: lastDateTime.toFormat('yyyy-M-d HH:mm:ss'),
                    genericdatatypeid: ''
                }
            });

            setRecentData({
                firstDateTime: firstDateTime,
                lastDateTime: lastDateTime,
                animateChart: animateChart,
                apiData: response
            });

        } catch (error) {
            console.error(error);
            setRecentChartHasError(true);
        } finally {
            setRecentChartIsLoading(false);
        }
    }

    async function updateLiveData() {
        setRealtimeDataHasError(false);
        setRealtimeDataIsLoading(true);

        try {
            let path = '';
            if (installationOrGroup === 'installation') {
                path += '/Installation'
            } else if (installationOrGroup === 'installationGroup') {
                path += '/InstallationGroup'
            }
            path += '/' + installationOrGroupId + '/CurrentData';

            const response = await apiInterface.makeRequest({
                token: token,
                path: path
            });

            const installationData = response?.InstallationData[0] ?? null;
            if (installationData !== null) {
                setRealtimeData({
                    ...realtimeData,
                    powerYieldKw: installationData?.Particle00Production ?? 0,
                    powerGridKw: Math.abs(installationData?.Particle02Grid) ?? 0,
                    powerUsageKw: installationData?.Particle01Consumption ?? 0,
                    powerStorageKw: Math.abs(installationData?.Particle03Storage) ?? 0,
                    storageChargedPercentage: installationData?.Particle04StorageStateOfCharge ?? 0,
                    storageState: installationData?.Particle03Storage > 0 ? 'discharging' : 'charging',
                    sunPowerPercentage: installationData?.Particle12IrradiationPV ?? 0
                });
            }
        } catch (error) {
            console.error(error);
            setRealtimeDataHasError(true);
        } finally {
            setRealtimeDataIsLoading(false);
        }
    }

    async function updateLongTermData({animateChart = false} = {}) {
        setLongTermChartIsLoading(true);
        setLongTermChartHasError(false);

        const firstDayOfCurrentMonth = DateTime.fromObject({
            day: 1,
            hour: 0,
            second: 0,
            millisecond: 0,
            zone: 'utc'
        });

        const chartStartDate = firstDayOfCurrentMonth.minus({
            months: 24
        });
        const chartEndDate = firstDayOfCurrentMonth.plus({
            months: 0
        });

        const firstDateTime = chartStartDate;
        const lastDateTime = chartEndDate;

        try {
            let path = '';
            if (installationOrGroup === 'installation') {
                path += '/Installation'
            } else if (installationOrGroup === 'installationGroup') {
                path += '/InstallationGroup'
            }
            path += '/' + installationOrGroupId + '/History/15';

            const response = await apiInterface.makeRequest({
                token: token,
                path: path,
                headers: {
                    fromdate: chartStartDate.toFormat('yyyy-M-d HH:mm:ss'),
                    thrudate: chartEndDate.toFormat('yyyy-M-d HH:mm:ss'),
                    genericdatatypeid: ''
                }
            });

            setLongTermChartData({
                firstDateTime: firstDateTime,
                lastDateTime: lastDateTime,
                apiData: response,
                animateChart: animateChart
            });
        } catch (error) {
            console.error(error);
            setLongTermChartHasError(true);
        } finally {
            setLongTermChartIsLoading(false);
        }
    }

    return (
        <PageWrapper>
            <div className="lc-component page-screen">
                <div className="logo-wrapper">
                    <img src={logo} alt="BestWatt" />
                </div>
                <div className="live-view">
                    <Overlay spinContent={realtimeDataIsLoading} visible={realtimeDataIsLoading || realtimeDataHasError}>
                        <div className="box">
                            {realtimeDataIsLoading &&
                                <AutorenewOutlinedIcon style={{fill: '#fff'}} fontSize="large" />
                            }
                            {realtimeDataHasError &&
                                <WarningIcon style={{fill: '#f00'}} fontSize="large" />
                            }
                        </div>
                    </Overlay>
                    <PowerFlow maxPowerYieldKw={realtimeData.maxPowerYieldKw} powerGridKw={realtimeData.powerGridKw} powerYieldKw={realtimeData.powerYieldKw}
                               powerUsageKw={realtimeData.powerUsageKw} powerStorageKw={realtimeData.powerStorageKw}
                               storageChargedPercentage={realtimeData.storageChargedPercentage} storageState={realtimeData.storageState}
                               visualizeSolarPowerPercentage={realtimeData.visualizeSolarPowerPercentage} sunPowerPercentage={realtimeData.sunPowerPercentage}
                    />
                </div>
                <div className="recent-chart">
                    <div className="inner">
                        <Overlay spinContent={recentChartIsLoading} visible={recentChartIsLoading || recentChartHasError}>
                            <div className="box">
                                {recentChartIsLoading &&
                                    <AutorenewOutlinedIcon style={{fill: '#fff'}} fontSize="large" />
                                }
                                {recentChartHasError &&
                                    <WarningIcon style={{fill: '#f00'}} fontSize="large" />
                                }
                            </div>
                        </Overlay>
                        <HistoryChart scale="5min"
                                      firstDateTime={recentData.firstDateTime}
                                      lastDateTime={recentData.lastDateTime}
                                      apiData={recentData.apiData}
                                      animateChart={recentData.animateChart}
                        />
                    </div>
                </div>
                <div className="long-term-chart">
                    <div className="inner">
                        <Overlay spinContent={longTermChartIsLoading} visible={longTermChartIsLoading || longTermChartHasError}>
                            <div className="box">
                                {longTermChartIsLoading &&
                                    <AutorenewOutlinedIcon style={{fill: '#fff'}} fontSize="large" />
                                }
                                {longTermChartHasError &&
                                    <WarningIcon style={{fill: '#f00'}} fontSize="large" />
                                }
                            </div>
                        </Overlay>
                        <HistoryChart scale="month"
                                      firstDateTime={longTermChartData.firstDateTime}
                                      lastDateTime={longTermChartData.lastDateTime}
                                      apiData={longTermChartData.apiData}
                                      animateChart={longTermChartData.animateChart}
                        />
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}
