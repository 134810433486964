import "./styles.scss";

export default function Button ({size = 'medium', onClick = () => {}, children, isDisabled = false, showLoadingAnimation = false}) {
    const classes = ['lc-component', 'button', 'size-' + size];
    if (showLoadingAnimation) {
        classes.push('show-loading-animation');
    }

    return (
        <button disabled={isDisabled} className={classes.join(' ')} onClick={onClick}>
            {children}
        </button>
    )
}
