import nl from "./nl";
import en from "./en";

const translations = {
    nl: nl,
    en: en
}


export default translations;
