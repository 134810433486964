import "./styles.scss";

import React, { useState, useContext } from 'react';
import MessageBox from "../../message-box";
import Button from "../../button";
import PageWrapper from "../../page-wrapper";
import { timeZonesNames } from "@vvo/tzdb";

import TimezoneContext from "../../../contexts/timezone";
import LocaleContext from "../../../contexts/locale";
import locales from "../../../locales";

export default function UrlGenerator({
    apiUrl = 'https://api.mybestwatt.com',
    installationOrGroupParam,
    installationOrGroupIdParam,
    liveDataUpdateIntervalSecondsParam,
    recentChartUpdateIntervalSecondsParam,
    tokenParam
}) {
    const initialInstallationDataState = {
        groups: [],
        groupInstallations: [],
        installations: []
    }

    const [installationOrGroup, setInstallationOrGroup] = useState(installationOrGroupParam);
    const [installationOrGroupId, setInstallationOrGroupId] = useState(installationOrGroupIdParam);
    const [liveDataUpdateIntervalSeconds, setLiveDataUpdateIntervalSeconds] = useState(liveDataUpdateIntervalSecondsParam ?? 600);
    const [recentChartUpdateIntervalSeconds, setRecentChartUpdateIntervalSeconds] = useState(recentChartUpdateIntervalSecondsParam ?? 900);
    const [token, setToken] = useState(tokenParam);

    const [installationData, setInstallationData] = useState(initialInstallationDataState);
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const [timezone, setTimezone] = useContext(TimezoneContext);
    const {locale, setLocale} = useContext(LocaleContext);

    async function getInstallations() {
        setErrorMessages([]);
        setLoading(true);

        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 5000);
            const response = await fetch(apiUrl + '/Session/Assets', { // todo replace for api interface
                signal: controller.signal,
                method: 'GET',
                headers: {
                    token: token
                }
            });
            clearTimeout(timeoutId);
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }

            let json = await response.json();

            if (json?.Result[0]?.Result === 'Ok') {
                const data = {
                    groups: json?.InstallationGroup ?? [],
                    groupInstallations: json?.InstallationGroupInstallations ?? [],
                    installations: json?.Installation ?? []
                }
                setInstallationData(data);
            } else {
                throw new Error('Result is not ok');
            }
        } catch (error) {
            console.log(error.message);
            setErrorMessages([error.message]);
            setInstallationData(initialInstallationDataState);
        }

        setLoading(false);
    }

    function getStructurizedDataFromApiData(apiInstallationData) {
        // set installation groups first
        const installationGroupsWithInstallations = [];
        for (const installationGroup of apiInstallationData.groups) {
            installationGroupsWithInstallations.push({
                id: installationGroup.ID,
                name: installationGroup.DisplayName,
                installationIds: [],
                installations: [],
                _apiData: installationGroup
            });
        }
        // inject installation id's and installations
        for (const apiGroupInstallation of apiInstallationData.groupInstallations) {
            const installationGroup = installationGroupsWithInstallations.filter((group) => {
                return group.id === apiGroupInstallation.InstallationGroupID
            })[0] ?? null;

            if (installationGroup !== null) {
                installationGroup.installationIds.push(apiGroupInstallation.InstallationID);

                const installation = apiInstallationData.installations.filter((installation) => {
                    return installation.ID === apiGroupInstallation.InstallationID
                })[0] ?? null;

                if (installation !== null) {
                    installationGroup.installations.push(installation);
                }
            }
        }

        const installationsNotInAGroup = [];
        for (const apiInstallation of apiInstallationData.installations) {
            const installationGroup = installationGroupsWithInstallations.filter((group) => {
                return group.installationIds.indexOf(apiInstallation.ID) !== -1
            })[0] ?? null;

            if (installationGroup === null) {
                installationsNotInAGroup.push(apiInstallation);
            }
        }

        return {
            installationGroupsWithInstallations: installationGroupsWithInstallations,
            installationsNotInAGroup: installationsNotInAGroup
        };
    }

    const installationGroupedNicely = () => {
        const structurizedData = getStructurizedDataFromApiData(installationData);
        let groupedInstallations, ungroupedInstallations;

        if (structurizedData.installationGroupsWithInstallations.length > 0) {
            groupedInstallations = structurizedData.installationGroupsWithInstallations.map((group, key) => {
                return <InstallationGroup
                    setInstallationOrGroup={setInstallationOrGroup}
                    setInstallationOrGroupId={setInstallationOrGroupId}
                    key={key} name={group.name} id={group.id} installations={group.installations} />
            });
        } else {
            groupedInstallations = <p>Nothing found</p>;
        }

        if (structurizedData.installationsNotInAGroup.length > 0) {

        } else {
            ungroupedInstallations = <p>Nothing found</p>
        }

        return (
            <div className="installation-groups">
                <h1>Installations</h1>
                {groupedInstallations}
                <h1>Ungrouped installations</h1>
                {ungroupedInstallations}
            </div>
        )
    }

    const baseUrl = window.location.protocol + '//' + window.location.host;

    const url = `${baseUrl}`
        + `?what=${encodeURIComponent(installationOrGroup ?? '')}`
        + `&what-id=${encodeURIComponent(installationOrGroupId ?? '')}`
        + `&t1=${encodeURIComponent(liveDataUpdateIntervalSeconds ?? '')}`
        + `&t2=${encodeURIComponent(recentChartUpdateIntervalSeconds ?? '')}`
        + `&locale=${encodeURIComponent(locale ?? '')}`
        + `&timezone=${encodeURIComponent(timezone ?? '')}`
        + `&token=${encodeURIComponent(token ?? '')}`;

    return (
        <PageWrapper centreContentVertical={true} padding={true}>
            <div className="lc-page settings">
                <p>
                    All settings are stored in the URL, use the URL below
                </p>
                <textarea className="lc-input" readOnly={true} value={url}/>

                <div className="input-wrapper">
                    <div className="input">
                        <label htmlFor="installationOrGroup">What</label>
                        <select onChange={(e) => setInstallationOrGroup(e.target.value)}
                                value={installationOrGroup ?? ''}
                                id="installationOrGroup"
                                className="lc-input"
                        >
                            <option value=""></option>
                            <option value="installation">Installation</option>
                            <option value="installationGroup">Installation group</option>
                        </select>
                    </div>
                    <div className="input">
                        <label htmlFor="installationOrGroupId">Id</label>
                        <input onChange={(e) => setInstallationOrGroupId(e.target.value)}
                               value={installationOrGroupId ?? ''}
                               id="installationOrGroupId"
                               type="number"
                               className="lc-input"
                               min={0}
                        />
                    </div>
                </div>

                <div className="input-wrapper">
                    <div className="input">
                        <label htmlFor="liveDataUpdateIntervalSeconds">Live data update interval seconds</label>
                        <input onChange={(e) => setLiveDataUpdateIntervalSeconds(e.target.value)}
                               value={liveDataUpdateIntervalSeconds ?? ''}
                               id="liveDataUpdateIntervalSeconds"
                               type="number"
                               min={0}
                               className="lc-input"
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="recentChartUpdateIntervalSeconds">Recent chart update interval seconds</label>
                        <input onChange={(e) => setRecentChartUpdateIntervalSeconds(e.target.value)}
                               value={recentChartUpdateIntervalSeconds ?? ''}
                               id="liveDataUpdateIntervalSeconds"
                               type="number"
                               min={0}
                               className="lc-input"
                        />
                        <p>
                            !
                            The longterm chart never updates
                        </p>
                    </div>
                </div>

                <div className="input-wrapper">
                    <div className="input">
                        <label htmlFor="language">Locale</label>
                        <select id="language"
                                className="lc-input"
                                value={locale ?? ''}
                                onChange={(e) => setLocale(e.target.value)}
                        >
                            {locales.map((locale) => {
                                return <option key={locale} value={locale}>{locale}</option>
                            })}
                        </select>
                    </div>
                    <div className="input">
                        <label htmlFor="timezone">Timezone</label>
                        <select id="timezone"
                                className="lc-input"
                                value={timezone ?? ''}
                                onChange={(e) => setTimezone(e.target.value)}
                        >
                            <option value=""> </option>
                            {timeZonesNames.map((name) => {
                                return <option key={name} value={name}>{name}</option>
                            })}
                        </select>
                        <div style={{marginTop: '1rem'}}>
                            <Button size="small" onClick={() => setTimezone('Europe/Amsterdam')}>
                                Set to "Europe/Amsterdam"
                            </Button>
                        </div>

                    </div>
                </div>

                <label htmlFor="token">Token</label>
                <input id="token"
                       onChange={(e) => setToken(e.target.value)}
                       value={token ?? ''}
                       className="lc-input"
                       type="text"
                />
                <div className="get-installations-button-wrapper">
                    <Button isDisabled={loading} showLoadingAnimation={loading} onClick={getInstallations}>
                        Get installations/groups
                    </Button>
                </div>
                {errorMessages.length > 0 &&
                    <MessageBox errorMessages={errorMessages} />
                }
                {installationGroupedNicely()}
            </div>
        </PageWrapper>
    )
}

function InstallationGroup({name = '', id = 0, installations = [], setInstallationOrGroup, setInstallationOrGroupId}) {
    return (
        <div className="installation-group">
            Group: {name}<br />
            Id: {id}<br />
            <Button onClick={() => {
                setInstallationOrGroup('installationGroup');
                setInstallationOrGroupId(id)
            }}>Select</Button>

            <div className="installations">
                {installations.map((installation) => {
                    return<div className="installation">
                        Installation: {installation.DisplayName}<br />
                        Id: {installation.ID}<br />
                        <Button onClick={() => {
                            setInstallationOrGroup('installation');
                            setInstallationOrGroupId(installation.ID)
                        }}>Select</Button>
                    </div>
                })}
            </div>
        </div>
    )
}
