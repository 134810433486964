import "./styles.scss";
import paper from 'paper';
import Chart from 'chart.js';
import {useContext, useEffect, useRef, useState} from 'react';
import useInterval from 'use-interval';
import AnimatedNumber from "animated-number-react";
import Translation from "../translation";
import LocaleContext from "../../contexts/locale";

import {ReactComponent as PowerLinesSvgIcon} from "../../images/power-lines-icon.svg";
import {ReactComponent as FlashIcon} from "../../images/flash-icon.svg";
import {ReactComponent as LightBulbIcon} from "../../images/light-bulb-icon.svg";

/*
Visualisation of the powergrid, wow such artwork, much creativity
@see https://textik.com/#44d6a8b951a3206a

                             +-------------------+
                             |                   |
                             |                   |
                             |    Opbrengst      |
                             |      Yield        |
                             |                   |
                             +-------------------+
                                     | | |
                                     | | |
                                     | | |
                                     | | |
                                     | | |
+-------------------+                | | |                      +-------------------+
|                   |         A      | | |        B             |                   |
|                   | <--------------+ | +--------------------> |                   |
|      Net          |                  |          E             |     Verbruik      |
|      Grid         | ----------------------------------------> |      Usage        |
|                   |                  |          D             |                   |
|                   |                  | +--------------------> |                   |
|                   |                  | |                      |                   |
+-------------------+                  | |                      +-------------------+
                                     C | |
                                       | |
                                       v |
                             +-------------------+
                             |                   |
                             |                   |
                             |      Opslag       |
                             |      Storage      |
                             |                   |
                             |                   |
                             +-------------------+

 Powerline A is for overproduction
 */

export default function PowerFlow(
    {
        powerYieldKw = 0,
        powerUsageKw = 0,
        powerGridKw = 0,
        powerStorageKw = 0,
        storageChargedPercentage = 0,
        storageState = '', // charging|discharging
        visualizeSolarPowerPercentage = true,
        maxPowerYieldKw = 0,
        sunPowerPercentage = 0,
    }) {

    const colors = {
        yield: '#34ff00',
        grid: '#ff0020',
        usage: '#ffb600',
        storage: '#4b2fff'
    }

    const numbersAnimationDuration = 500;

    const { locale } = useContext(LocaleContext);

    // refs
    const rootElement = useRef(null);
    const scaleWrapper = useRef(null);
    const powerLinesCanvas = useRef(null);
    const sunPowerVisualiserOverlayCanvas = useRef(null);

    // states
    const [sunPowerPercentageChart, setSunPowerPercentageChart] = useState(null);
    const [powerLinesPaper, setPowerLinesPaper] = useState(null);
    const [powerLineAPaperPath, setPowerLineAPaperPath] = useState(null);
    const [powerLineBPaperPath, setPowerLineBPaperPath] = useState(null);
    const [powerLineCPaperPath, setPowerLineCPaperPath] = useState(null);
    const [powerLineDPaperPath, setPowerLineDPaperPath] = useState(null);
    const [powerLineEPaperPath, setPowerLineEPaperPath] = useState(null);
    const [powerLineAnimationDots, setPowerLineAnimationDots] = useState([]);

    // init power percentage chart
    useEffect(() => {
        if (visualizeSolarPowerPercentage) {
            const ctx = sunPowerVisualiserOverlayCanvas.current.getContext('2d');
            const sunPowerPercentageChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    datasets: [{
                        data: [0, 100], // start 100% blacked out
                        backgroundColor: [
                            'rgba(0, 0, 0, 0)',
                            'rgba(18, 18, 18, 0.9)'
                        ],
                        borderWidth: 0
                    }]
                },
                options: {
                    tooltips: {
                        enabled: false
                    },
                    animation: {
                        duration: 0 // disable animation on first load
                    },
                    legend: {
                        display: false
                    }
                }
            });
            setSunPowerPercentageChart(sunPowerPercentageChart);
            sunPowerPercentageChart.data.datasets[0].data = [sunPowerPercentage, 100 - sunPowerPercentage];
            sunPowerPercentageChart.update();
        }
    }, []);

    // init power lines paper
    useEffect(() => {
        const powerLinesPaper = paper.setup(powerLinesCanvas.current);

        const powerLinePaths = {
            a: [[295, 150], [295, 295], [150, 295]],
            b: [[305, 150], [305, 295], [450, 295]],
            c: [[300, 150], [300, 450]],
            d: [[305, 450], [305, 305], [450, 305]],
            e: [[150, 300], [450, 300]]
        };

        setPowerLineAPaperPath(roundCorners(new powerLinesPaper.Path({
            segments: powerLinePaths.a,
            strokeJoin: 'round',
            strokeColor: {
                gradient: {
                    stops: [colors.yield, colors.yield, colors.yield, colors.grid],
                },
                origin: [295, 0],
                destination: [150, 0]
            },
            strokeWidth: 2,
            visible: false,
            opacity: 0.6
        }), 15));
        setPowerLineBPaperPath(roundCorners(new powerLinesPaper.Path({
            segments: powerLinePaths.b,
            strokeJoin: 'round',
            strokeColor: {
                gradient: {
                    stops: [colors.yield, colors.yield, colors.usage],
                },
                origin: [305, 0],
                destination: [450, 0]
            },
            strokeWidth: 2,
            visible: false,
            opacity: 0.6
        }), 15));
        setPowerLineCPaperPath(roundCorners(new powerLinesPaper.Path({
            segments: powerLinePaths.c,
            strokeJoin: 'round',
            strokeColor: {
                gradient: {
                    stops: [colors.yield, colors.yield, colors.yield, colors.storage],
                },
                origin: [300, 150],
                destination: [300, 450]
            },
            strokeWidth: 2,
            visible: false,
            opacity: 0.6
        }), 15));
        setPowerLineDPaperPath(roundCorners(new powerLinesPaper.Path({
            segments: powerLinePaths.d,
            strokeJoin: 'round',
            strokeColor: {
                gradient: {
                    stops: [colors.storage, colors.storage, colors.usage],
                },
                origin: [305, 0],
                destination: [450, 0]
            },
            strokeWidth: 2,
            visible: false,
            opacity: 0.6
        }), 15));
        setPowerLineEPaperPath(roundCorners(new powerLinesPaper.Path({
            segments: powerLinePaths.e,
            strokeJoin: 'round',
            strokeColor: {
                gradient: {
                    stops: [colors.grid, colors.grid, colors.usage],
                },
                origin: [150, 0],
                destination: [450, 0]
            },
            strokeWidth: 2,
            visible: false,
            opacity: 0.6
        }), 15));

        powerLinesPaper.view.draw();

        /**
         * Slightly modified version, which skips first and last point
         * @see https://gist.github.com/winduptoy/8b5c574e0e33bf547a31
         */
        function roundCorners(path,radius) {
            let segments = path.segments.slice(0);
            path.removeSegments();

            for(let i = 0, l = segments.length; i < l; i++) {
                let curPoint = segments[i].point;
                let nextPoint = segments[i + 1 == l ? 0 : i + 1].point;
                let prevPoint = segments[i - 1 < 0 ? segments.length - 1 : i - 1].point;
                let nextDelta = curPoint.subtract(nextPoint);
                let prevDelta = curPoint.subtract(prevPoint);
                nextDelta.length = radius;
                prevDelta.length = radius;
                if (i > 0) {
                    path.add(
                        new paper.Segment(
                            curPoint.subtract(prevDelta),
                            null,
                            prevDelta.divide(2)
                        )
                    );
                } else {
                    path.add(curPoint);
                }
                if (i < (segments.length - 1)) {
                    path.add(
                        new paper.Segment(
                            curPoint.subtract(nextDelta),
                            nextDelta.divide(2),
                            null
                        )
                    );
                } else {
                    path.add(curPoint);
                }
            }
            return path;
        }

        setPowerLinesPaper(powerLinesPaper);
    }, []);

    // powerline dots animation function
    useInterval(() => {
        if (powerLinesPaper === null) return; // just to be sure

        if (powerLineAPaperPath.visible) {
            const animationDot = new PowerLineAnimationDot({
                color: colors.yield,
                paperPath: powerLineAPaperPath,
                paperInstance: powerLinesPaper,
                powerLineLetter: 'a',
                duration: 2500
            });
            setPowerLineAnimationDots((prevState => {
                return [... prevState, animationDot];
            }));
        }

        if (powerLineBPaperPath.visible) {
            const animationDot = new PowerLineAnimationDot({
                color: colors.yield,
                paperPath: powerLineBPaperPath,
                paperInstance: powerLinesPaper,
                powerLineLetter: 'b',
                duration: 2500
            });
            setPowerLineAnimationDots((prevState => {
                return [... prevState, animationDot];
            }));
        }

        if (powerLineCPaperPath.visible) {
            const animationDot = new PowerLineAnimationDot({
                color: colors.yield,
                paperPath: powerLineCPaperPath,
                paperInstance: powerLinesPaper,
                powerLineLetter: 'c',
                duration: 2500
            });
            setPowerLineAnimationDots((prevState => {
                return [... prevState, animationDot];
            }));
        }

        if (powerLineDPaperPath.visible) {
            const animationDot = new PowerLineAnimationDot({
                color: colors.storage,
                paperPath: powerLineDPaperPath,
                paperInstance: powerLinesPaper,
                powerLineLetter: 'd',
                duration: 2500
            });
            setPowerLineAnimationDots((prevState => {
                return [... prevState, animationDot];
            }));
        }

        if (powerLineEPaperPath.visible) {
            const animationDot = new PowerLineAnimationDot({
                color: colors.grid,
                paperPath: powerLineEPaperPath,
                paperInstance: powerLinesPaper,
                powerLineLetter: 'e',
                duration: 2500
            });
            setPowerLineAnimationDots((prevState => {
                return [... prevState, animationDot];
            }));
        }
    }, 1000, false);

    // update sunpower
    useEffect(() => {
        if (sunPowerPercentageChart !== null) {
            sunPowerPercentageChart.options.animation.duration = 1000;
            sunPowerPercentageChart.data.datasets[0].data = [sunPowerPercentage, 100 - sunPowerPercentage];
            sunPowerPercentageChart.update();
        }
    }, [sunPowerPercentage]);

    // update powerlines visibility
    useEffect(() => {
        if (powerLinesPaper === null) return;

        const powerYield = powerYieldKw;
        const powerUsage = powerUsageKw;
        const powerStorage = powerStorageKw;

        let maxPower = powerYield; // used for the animations
        if (powerUsage > maxPower) {
            maxPower = powerUsage;
        }

        // determine yielded power distribution
        let yieldedPowerToGrid = 0; // powerline A
        let yieldedPowerToUsage = 0; // powerline B
        let yieldedPowerToStorage = 0; // powerline C
        let storagePowerToUsage = 0; // powerline D
        let gridPowerToUsage = 0; // powerline E

        let yieldedPowerAvailable = powerYield;
        let powerUsageSatisfied = 0;

        // charge storage
        if (storageState === 'charging') {
            yieldedPowerToStorage = powerStorage;
            yieldedPowerAvailable -= yieldedPowerToStorage;
        }

        if (yieldedPowerAvailable < 0) {
            yieldedPowerAvailable = 0;
        }

        // power usage
        if (powerUsage > 0) {
            // storage power to usage
            if (storageState === 'discharging') {
                storagePowerToUsage = powerStorage;
                powerUsageSatisfied += storagePowerToUsage;
            }

            if (yieldedPowerAvailable > powerUsage - powerUsageSatisfied) {
                yieldedPowerToUsage = powerUsage - powerUsageSatisfied;
            } else {
                yieldedPowerToUsage = yieldedPowerAvailable;
            }
            yieldedPowerAvailable -= yieldedPowerToUsage;
            powerUsageSatisfied += yieldedPowerToUsage;
        }

        // define if we draw power to grid, or if we deliver back
        // finally rest of the yielded power goes back to the grid
        if (yieldedPowerAvailable > 0) {
            yieldedPowerToGrid = yieldedPowerAvailable;
        } else if (powerUsageSatisfied < powerUsage) {
            gridPowerToUsage = powerUsage - powerUsageSatisfied;
        }

        // powerline A
        if (yieldedPowerToGrid > 0) {
            powerLineAPaperPath.visible = true;
        } else {
            powerLineAPaperPath.visible = false;
        }
        // powerline B
        if (yieldedPowerToUsage > 0) {
            powerLineBPaperPath.visible = true;
        } else {
            powerLineBPaperPath.visible = false;
        }
        // powerline C
        if (yieldedPowerToStorage > 0) {
            powerLineCPaperPath.visible = true;
        } else {
            powerLineCPaperPath.visible = false;
        }
        // powerline D
        if (storagePowerToUsage > 0) {
            powerLineDPaperPath.visible = true;
        } else {
            powerLineDPaperPath.visible = false;
        }
        // powerline E
        if (gridPowerToUsage > 0) {
            powerLineEPaperPath.visible = true;
        } else {
            powerLineEPaperPath.visible = false;
        }
        powerLinesPaper.view.draw();
    }, [powerYieldKw, powerUsageKw, powerStorageKw, powerGridKw, storageState]);

    // remove orphan animation dots (dots without a parent line), poor dots...
    useEffect(() => {
        for (const powerLineAnimationDot of powerLineAnimationDots) {
            if (powerLineAnimationDot.animationIsCompleted === false) {
                if (powerLineAnimationDot.powerLineLetter === 'a' && powerLineAPaperPath.visible === false) {
                    powerLineAnimationDot.abort();
                } else if (powerLineAnimationDot.powerLineLetter === 'b' && powerLineBPaperPath.visible === false) {
                    powerLineAnimationDot.abort();
                } else if (powerLineAnimationDot.powerLineLetter === 'c' && powerLineCPaperPath.visible === false) {
                    powerLineAnimationDot.abort();
                } else if (powerLineAnimationDot.powerLineLetter === 'd' && powerLineDPaperPath.visible === false) {
                    powerLineAnimationDot.abort();
                } else if (powerLineAnimationDot.powerLineLetter === 'e' && powerLineEPaperPath.visible === false) {
                    powerLineAnimationDot.abort();
                }
            }

        }
    }, [powerUsageKw, powerYieldKw, powerGridKw, powerStorageKw, storageState]);

    // animation dot garbage collector
    useInterval(() => {
        const animationDots = [];

        for (const powerLineAnimationDot of powerLineAnimationDots) {
            if (powerLineAnimationDot.isAborted === false && powerLineAnimationDot.animationIsCompleted === false) {
                animationDots.push(powerLineAnimationDot);
            }
        }
        setPowerLineAnimationDots(animationDots);
    }, 5000);

    window.addEventListener('resize', () => {
        updateScale();
    });
    window.addEventListener('load', () => {
        updateScale();
    });
    updateScale();

    function updateScale() {
        if (rootElement.current === null || scaleWrapper.current === null) return;
        const defaultSize = 600; // perfect square, so we only need one size yo

        const availableWidth = rootElement.current.offsetWidth;
        const availableHeight = rootElement.current.offsetHeight;

        // inspired by: https://css-tricks.com/scaled-proportional-blocks-with-css-and-javascript/
        let scale = Math.min(
            availableWidth / defaultSize,
            availableHeight / defaultSize
        );

        // if (scale > 2.5) {
        //     scale = 2.5;
        // }

        scaleWrapper.current.style.transform = 'scale(' + scale + ')';
    }

    function formatAnimatedNumberValue(value) {
        if (value === 0) return '0';

        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
        if (value > 100) {
            options.maximumFractionDigits = 0;
            options.minimumFractionDigits = 0;
        }

        return value.toLocaleString(locale, options);
    }

    function formatRoundedAnimatedNumberValue(value) {
        return parseInt(value);
    }

    const styles = {
        largeIcon: {
            width: 50,
            height: 50,
        }
    };

    return (
        <div ref={rootElement} className="lc-component power-flow">
            <div ref={scaleWrapper} className="power-flow-overview">
                <canvas ref={powerLinesCanvas} width="600" height="600"
                        className="power-lines"></canvas>
                <div className={'item power-yield' + (powerYieldKw !== 0 ? ' active' : '')}>
                    <p className="title position-top"><Translation code="yield">Yield</Translation></p>
                    {visualizeSolarPowerPercentage &&
                        <div className="sun-power-percentage">
                            <div className="svg"><svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 24 24" width="40"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="#ffe90b" d="M6.05 4.14l-.39-.39c-.39-.39-1.02-.38-1.4 0l-.01.01c-.39.39-.39 1.02 0 1.4l.39.39c.39.39 1.01.39 1.4 0l.01-.01c.39-.38.39-1.02 0-1.4zM3.01 10.5H1.99c-.55 0-.99.44-.99.99v.01c0 .55.44.99.99.99H3c.56.01 1-.43 1-.98v-.01c0-.56-.44-1-.99-1zm9-9.95H12c-.56 0-1 .44-1 .99v.96c0 .55.44.99.99.99H12c.56.01 1-.43 1-.98v-.97c0-.55-.44-.99-.99-.99zm7.74 3.21c-.39-.39-1.02-.39-1.41-.01l-.39.39c-.39.39-.39 1.02 0 1.4l.01.01c.39.39 1.02.39 1.4 0l.39-.39c.39-.39.39-1.01 0-1.4zm-1.81 15.1l.39.39c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41l-.39-.39c-.39-.39-1.02-.38-1.4 0-.4.4-.4 1.02-.01 1.41zM20 11.49v.01c0 .55.44.99.99.99H22c.55 0 .99-.44.99-.99v-.01c0-.55-.44-.99-.99-.99h-1.01c-.55 0-.99.44-.99.99zM12 5.5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-.01 16.95H12c.55 0 .99-.44.99-.99v-.96c0-.55-.44-.99-.99-.99h-.01c-.55 0-.99.44-.99.99v.96c0 .55.44.99.99.99zm-7.74-3.21c.39.39 1.02.39 1.41 0l.39-.39c.39-.39.38-1.02 0-1.4l-.01-.01c-.39-.39-1.02-.39-1.41 0l-.39.39c-.38.4-.38 1.02.01 1.41z"/></svg></div>
                            <div className="percentage-canvas-wrapper">
                                <canvas ref={sunPowerVisualiserOverlayCanvas} width="30" height="30" className="overlay-canvas"></canvas>
                            </div>
                        </div>
                    }
                    <p className="icon"><FlashIcon width="50px" height="50px" /></p>
                    <p className="value-wrapper">
                        <span className="value">
                            <AnimatedNumber value={powerYieldKw}
                                            formatValue={formatAnimatedNumberValue}
                                            duration={numbersAnimationDuration}
                            />
                        </span>
                        <span className="unit">kW</span>
                    </p>
                    {maxPowerYieldKw !== 0 &&
                        <p className="value-caption">
                            <span className="text">max</span>
                            <span className="value">{maxPowerYieldKw}</span>
                            <span className="unit">kW</span>
                        </p>
                    }
                </div>
                <div className={'item power-grid' + (powerGridKw !== 0 ? ' active' : '')}>
                    <p className="title position-bottom"><Translation code="grid" /></p>
                    <p className="icon">
                        <PowerLinesSvgIcon width="50px" height="50px" />
                    </p>
                    <p className="value-wrapper">
                        <span className="value">
                            <AnimatedNumber value={powerGridKw}
                                            formatValue={formatAnimatedNumberValue}
                                            duration={numbersAnimationDuration}
                            />
                        </span>
                        <span className="unit">kW</span>
                    </p>
                </div>
                <div className={'item power-usage' + (powerUsageKw !== 0 ? ' active' : '')}>
                    <p className="title position-bottom"><Translation code="consumption" /></p>
                    <p className="icon"><LightBulbIcon width="50px" height="50px" /></p>
                    <p className="value-wrapper">
                        <span className="value">
                            <AnimatedNumber value={powerUsageKw}
                                            formatValue={formatAnimatedNumberValue}
                                            duration={numbersAnimationDuration}
                            />
                        </span>
                        <span className="unit">kW</span>
                    </p>
                </div>
                <div className={'item power-storage' + (powerStorageKw !== 0 ? ' active' : '')}>
                    <p className="title position-bottom"><Translation code="storage" /></p>
                    <p className="icon">
                        <span className="storage-icon-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.9 70.1"><path className="content" d="M107.7 15.5h11.9c0.9 0 1.8 0.4 2.4 1 0.6 0.6 1 1.5 1 2.4v32.5c0 0.9-0.4 1.8-1 2.4 -0.6 0.6-1.4 1-2.3 1h-11.9v9.8c0 1.6-0.6 3-1.7 4.1 -1 1-2.5 1.7-4 1.7H5.7c-1.6 0-3-0.6-4-1.7C0.7 67.4 0 66 0 64.4V5.7c0-1.6 0.6-3 1.7-4C2.7 0.6 4.2 0 5.7 0h96.2c1.6 0 3 0.7 4.1 1.7 1 1 1.7 2.5 1.7 4.1V15.5L107.7 15.5zM117.2 21.1h-9.6V49h9.6V21.1L117.2 21.1zM102 51.8V18.3 5.7c0 0 0 0 0 0 0 0 0 0 0 0H5.7c0 0 0 0 0 0C5.7 5.7 5.7 5.7 5.7 5.7V64.4c0 0 0 0 0 0.1 0 0 0 0 0.1 0h96.2c0 0 0 0 0 0 0 0 0 0 0 0V51.8L102 51.8z"/></svg>
                            <span className="percentage-charged-overlay-wrapper">
                                <span className="visual-overlay" style={{width: storageChargedPercentage + '%'}}></span>
                                <span className="numeric-overlay"><span>
                                    <AnimatedNumber value={storageChargedPercentage}
                                                    formatValue={formatRoundedAnimatedNumberValue}
                                                    duration={numbersAnimationDuration}
                                    />
                                </span>%</span>
                            </span>
                        </span>
                    </p>
                    <p className="value-wrapper">
                        <span className="value">
                            <AnimatedNumber value={powerStorageKw}
                                            formatValue={formatAnimatedNumberValue}
                                            duration={numbersAnimationDuration}
                            />
                        </span>
                        <span className="unit">kW</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

class PowerLineAnimationDot {
    paperInstance = null;
    paperPath = null;
    duration = 0;
    animationIsCompleted = false;
    powerLineLetter = '';
    isAborted = false;
    #startTime = null;
    #paperObject;

    constructor({paperInstance, paperPath, duration, color, powerLineLetter}) {
        this.duration = duration;
        this.paperInstance = paperInstance;
        this.paperPath = paperPath;
        this.powerLineLetter = powerLineLetter;
        this.#startTime = Date.now();

        let location = this.paperPath.getLocationAt(0);
        this.#paperObject = new paper.Path.Circle(location.getPoint(), 3);
        this.#paperObject.fillColor = color;
        this.#paperObject.strokeWidth = 2;

        this.animationStep();
    }

    abort() {
        this.isAborted = true;
        this.#paperObject.remove();
    }

    animationStep() {
        if (this.isAborted) return;
        let now = Date.now();
        let delta = now - this.#startTime;
        let completed = delta / this.duration;
        if (completed >= 1) {
            this.animationIsCompleted = true;
            this.#paperObject.remove();
            return;
        } else {
            window.requestAnimationFrame(() => {
                this.animationStep();
            });
        }
        let location = this.paperPath.getLocationAt(completed * this.paperPath.length);
        this.#paperObject.position = location.getPoint();
    }
}
