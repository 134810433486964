import "./styles.scss";

import { useRef, useEffect, useState, useContext } from "react";
import Chart from 'chart.js';
import 'chartjs-adapter-luxon';
import { Settings as LuxonSettings, DateTime } from 'luxon';

import TimezoneContext from "../../contexts/timezone";
import LocaleContext from "../../contexts/locale";

export default function HistoryChart({
    scale = '5min', // 5min|day|month
    firstDateTime, // Luxon JS date object
    lastDateTime = null, // Luxon JS date object
    animateChart = true,
    apiData = {} // the data from the API history call
}) {
    const chartCanvas = useRef(null);
    const [chart, setChart] = useState(null);

    Chart.defaults.global.defaultFontSize = window.innerWidth * 0.007;

    const [timezone] = useContext(TimezoneContext);
    const { locale } = useContext(LocaleContext);

    LuxonSettings.defaultLocale = locale;

    // init chart
    useEffect(() => {
        const ctx = chartCanvas.current.getContext('2d');

        const chart = new Chart(ctx, {
            type: 'line',
            options: {
                animation: {
                    duration: 1500
                },
                tooltips: {
                    cornerRadius: 0,
                    xPadding: 10,
                    yPadding: 10,
                    backgroundColor: '#333',
                    titleFontSize: 14,
                    titleFontColor: '#ddd',
                    bodyFontSize: 14,
                    bodyFontColor: '#ddd',
                    callbacks: {
                        label: (tooltipItem, chart) => {
                            let dataSet = chart.datasets[tooltipItem.datasetIndex];
                            return dataSet._labelWithoutUnit + ': ' + tooltipItem.yLabel.toLocaleString(locale, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            }) + ' ' + chart.datasets[tooltipItem.datasetIndex]._unit;
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10
                    }
                },
                legend: {
                    position: 'bottom',
                    align: 'center'
                },
                // aspectRatio: 1.5,
                maintainAspectRatio: false,
                ticks: 'auto',
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: 'rgba(255,255,255,0)'
                        },
                        offset: true,
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: 'hour',
                            displayFormats: {
                                hour: 'H:mm',
                                day: 'ccc dd-LL-yyyy',
                                month: 'LLL yyyy'
                            }
                        }
                    }],
                    yAxes: [{ // we need this extra hidden axis because we don't want to stack the consumption results on top of the yield results in the linechart
                        id: 'consumption',
                        stacked: false,
                        display: false
                    }, {
                        id: 'yield',
                        stacked: true,
                        gridLines: {
                            color: 'rgba(255,255,255,0.2)'
                        }
                    }]
                }
            }
        });

        setChart(chart);
    }, []);

    useEffect(() => {
        function handleResize() {
            if (chart !== null) {
                Chart.defaults.global.defaultFontSize = window.innerWidth * 0.007;
                chart.update();
                console.log('updatin da good shit');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const baseDataSets = {
        3002: {
            _labelTranslationCode: 'yield_solar',
            yAxisID: 'yield',
            stack: 'yieldStack',
            label: '',
            backgroundColor: 'rgb(255,235,3)',
            hoverBackgroundColor: 'rgb(255,235,3)',
            borderColor: 'rgb(255,235,3)',
            borderWidth: 0,
            lineTension: 0.2,
            type: 'line',
            data: []
        },
        3004: {
            _labelTranslationCode: 'yield_wind',
            yAxisID: 'yield',
            label: '',
            stack: 'yieldStack',
            backgroundColor: 'rgb(4,191,1)',
            hoverBackgroundColor: 'rgb(4,191,1)',
            borderColor: 'rgb(4,191,1)',
            borderWidth: 0,
            lineTension: 0.2,
            type: 'line',
            data: [],
        },
        3030: {
            _labelTranslationCode: 'power_usage',
            yAxisID: 'consumption',
            label: '',
            backgroundColor: 'rgba(255,0,32, 0.1)',
            hoverBackgroundColor: 'rgba(255,0,32, 0.5)',
            borderColor: 'rgb(255,0,32)',
            borderWidth: 1,
            lineTension: 0.2,
            type: 'line',
            data: []
        },
        6001: {
            _labelTranslationCode: 'yield_prognosis',
            yAxisID: 'yield',
            label: '',
            backgroundColor: 'rgba(0,255,217,0)',
            borderColor: 'rgb(0,255,217)',
            borderWidth: 1,
            lineTension: 0.2,
            borderDash: [5,4],
            type: 'line',
            data: []
        }
    };

    // update chart
    useEffect(() => {
        if (chart == null) return;

        chart.data.labels = [];
        chart.options.animation.duration = animateChart ? 1000 : 0;

        // console.log(firstDateTime.toString());
        // console.log(lastDateTime.toString());

        switch (scale) {
            case 'month' :
                LuxonSettings.defaultZoneName = 'utc';

                // set the x axis labels
                const duration = lastDateTime.diff(firstDateTime, 'months');
                for (let months = 0; months <= duration.months; months ++) {
                    chart.data.labels.push(
                        firstDateTime.plus({
                            months: months
                        })
                    );
                }

                baseDataSets["3002"].type = 'bar';
                baseDataSets["3004"].type = 'bar';
                baseDataSets["3030"].type = 'bar';
                chart.options.scales.xAxes[0].time.unit = 'month';
                chart.options.scales.xAxes[0].offset = true;
                break;
            case '5min' :
                LuxonSettings.defaultZoneName = timezone;

                chart.options.scales.xAxes[0].time.unit = 'hour';
                chart.options.scales.xAxes[0].offset = false;
                break;
            default :
                throw new Error('Unknown scale');
        }

        // rebuild dataSets
        chart.data.datasets = [];
        let dataSets = {};
        for (let [genericDataTypeId, dataSet] of Object.entries(baseDataSets)) {
            dataSets[genericDataTypeId] = dataSet;
            dataSet.data = [];
        }

        // set dataset labels
        for (let item of apiData.Legend) {
            if (typeof dataSets[item.GenericDataTypeID] !== 'undefined') {
                let dataSet = dataSets[item.GenericDataTypeID];
                dataSet.label = item.DisplayName + ' ' + item.Unit;
                dataSet._labelWithoutUnit = item.DisplayName;
                dataSet._unit = item.Unit;
            }
        }

        for (let item of apiData.Data) {
            if (typeof dataSets[item.GenericDataTypeID] !== 'undefined') {
                // todo fix
                if (scale === 'month') { // temp bugfix: https://gitlab.com/lucus/bestwatt.app/-/issues/54
                    if (item.Date.substr(8, 2) !== '01') {
                        item.Date = item.Date.substr(0, 8) + '01' + item.Date.substr(10);
                    }
                }
                if (scale === '5min') {
                    dataSets[item.GenericDataTypeID].data.push({
                        x: DateTime.fromISO(item.UTC, {zone: 'utc'}),
                        y: item.Value
                    });
                } else {
                    dataSets[item.GenericDataTypeID].data.push({
                        x: DateTime.fromISO(item.Date, {zone: 'utc'}),
                        y: item.Value
                    });
                }
            }
        }

        // fill missing stuff, in some cases the API may return nothing for a couple of months before returning values
        // for example on a year graph when the installation started somewhere during that year
        // chart.js doesn't like this, so we make sure the label and data amounts are always the same
        // not needed on scale 19, thankfully because it's pretty CPU heavy
        if (scale !== '5min') {
            for (let [genericDataTypeId, dataSet] of Object.entries(dataSets)) {
                const cleanData = [];
                for (const label of chart.data.labels) {
                    let value = 0;

                    const actualValue = dataSet.data.filter((dataPoint) => {
                        return dataPoint.x.equals(label);
                    })[0];

                    if (typeof actualValue !== 'undefined') {
                        value = actualValue.y;
                    }

                    cleanData.push({
                        x: label,
                        y: value
                    });
                }
                dataSet.data = cleanData;
            }
        }

        // only add the dataSets which actually contain data
        for (let [genericDataTypeId, dataSet] of Object.entries(dataSets)) {
            if (dataSet.data.length > 0) {
                let anyValueNotZero = false;
                for (const dataPoint of dataSet.data) {
                    if (dataPoint.y !== 0) {
                        anyValueNotZero = true;
                        break;
                    }
                }
                if (anyValueNotZero) {
                    chart.data.datasets.push(dataSet)
                }
            }
        }

        // sort all data, just to be sure, I think/assume chart.js prefers data in the right order
        for (let dataSet of chart.data.datasets) {
            dataSet.data.sort((a, b) => {
                return a.x - b.x;
            });
        }

        // get max value to calculate the ticks ourself, we do this to sync the ticks between the multiple y axis
        // we use multiple y axis because not everything should be stacked in the line-chart mode
        let maxValue = 0;
        let minValue = 0;

        // init stacked values
        let stackedValues = {};
        for (let dataSet of chart.data.datasets) {
            if (typeof dataSet.stack !== 'undefined') {
                stackedValues[dataSet.stack] = [];
            }
        }
        for (const dataSet of chart.data.datasets) {
            if (typeof dataSet.stack !== 'undefined') {
                dataSet.data.forEach((data, key) => {
                    if (typeof stackedValues[dataSet.stack][key] === 'undefined') {
                        stackedValues[dataSet.stack][key] = 0;
                    }
                    stackedValues[dataSet.stack][key] += data.y;
                });
            }
        }

        // not stacked
        for (let dataSet of chart.data.datasets) {
            if (typeof dataSet.stack === 'undefined') {
                dataSet.data.forEach((data) => {
                    if (data.y > maxValue) {
                        maxValue = data.y;
                    }
                    if (data.y < minValue) {
                        minValue = data.y;
                    }
                });
            }
        }
        // check stacked
        for (let [key, values] of Object.entries(stackedValues)) {
            for (const value of values) {
                if (value > maxValue) {
                    maxValue = value;
                }
                if (value < minValue) {
                    minValue = value;
                }
            }
        }

        const labelCount = 10;
        const maxValueRounded = calculateMax(10, maxValue);
        chart.options.scales.yAxes.forEach((axis) => {
            axis.ticks = {
                max: maxValueRounded,
                min: 0,
                stepSize: maxValueRounded / labelCount,
                callback: (value, index, values) => {
                    return value.toLocaleString(locale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                    });
                }
            }
        });

        chart.update();

    }, [firstDateTime, lastDateTime, apiData, animateChart]);

    // cheers https://github.com/chartjs/Chart.js/issues/3484#issuecomment-378877048
    function calculateMax (amountOfLabels, max) {
        // If max is divisible by amount of labels, then it's a perfect fit
        if (max % amountOfLabels === 0) {
            return max;
        }
        // If max is not divisible by amount of labels, let's find out how much there
        // is missing from max so it could be divisible
        const diffDivisibleByAmountOfLabels = amountOfLabels - (max % amountOfLabels);

        // Add missing value to max to get it divisible and achieve perfect fit
        return max + diffDivisibleByAmountOfLabels;
    }

    return (
        <div className="lc-component installation-or-group-history-chart">
            <canvas ref={chartCanvas} />
        </div>
    )
}
