import "./styles.scss";

export default function PageWrapper({padding = false, centreContentVertical = false, children}) {
    const classes = ['lc-component', 'page-wrapper'];
    if (padding) {
        classes.push('content-default-padding');
    }

    if (centreContentVertical) {
        classes.push('content-center-vertical');
    }

    return (
        <div className={classes.join(' ')}>
            <div className="inner">
                {children}
            </div>
        </div>
    )
}
