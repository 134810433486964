import { useContext } from "react";
import LocaleContext from "../../contexts/locale";
import translations from "../../translations";

export default function Translation ({code = '', children}) {
    const { locale } = useContext(LocaleContext);
    const language = locale.substr(0, 2);

    const translation = translations[language][code] ?? null;

    let returnValue;
    if (translation !== null) {
        returnValue = <span>{translation}</span>
    } else {
        returnValue = <span style={{color: '#f00'}}>Translation "{code}" missing for language "{language}"</span>
    }

    return returnValue;
}
