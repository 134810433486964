/**
 * Any other API error, for example 404 not found
 */
export class Generic extends Error {
    message = 'Generic API error';
    constructor(message = null) {
        super(message);
        if (message !== null) {
            this.message = message;
        }
    }
}
