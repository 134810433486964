import './styles.scss';

import { Info } from 'luxon';
import PageWrapper from "../../page-wrapper";

export default function BrowserCapabilitiesCheck() {
    function toLocaleStringSupportsLocales() {
        const number = 0;
        try {
            number.toLocaleString('i');
        } catch (e) {
            return e.name === 'RangeError';
        }
        return false;
    }

    const checks = {
        timezones: Info.features().zones,
        intl: Info.features().intl,
        numberToLocaleString: toLocaleStringSupportsLocales
    };

    return (
        <PageWrapper padding={true} centreContentVertical={true}>
            <h1>
                Browser capabilities check
            </h1>
            <p>
                If any of the tests below fails the app might not work as expected. In that case consider installing a
                more up-to-date browser.
            </p>
            <ul style={{listStyleType: 'none'}}>
                <li><CheckResult passed={checks.timezones} /> IANA support (timezones)<br /></li>
                <li><CheckResult passed={checks.intl} /> Intl (e.g. date formatting in locales)<br /></li>
                <li><CheckResult passed={checks.numberToLocaleString} /> Number.prototype.toLocaleString()<br /></li>
            </ul>
            <h2>
                Browser info:
            </h2>
            <ul>
                <li>Platform: {navigator?.platform ?? '-'}</li>
                <li>Useragent: {navigator?.userAgent ?? '-'}</li>
                <li>Language: {navigator?.language ?? '-'}</li>
                <li>Installed languages:
                    <ul>
                        {navigator.languages.map((language, key) => {
                            return <li key={key}>{language}</li>
                        })}
                    </ul>
                </li>
            </ul>
        </PageWrapper>
    )
}

function CheckResult({passed = true}) {
    return (
        <span style={{color: passed ? '#0f0' : '#f00'}}>{passed ? '✓️' : '×'}</span>
    )
}

