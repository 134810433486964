import './styles.scss';
import { Switch, Route, useLocation } from 'react-router-dom';

import Header from "./components/header/index.jsx";
import React, { useState, useEffect } from "react";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import Screen from "./components/pages/screen/index.jsx";
import UrlGenerator from "./components/pages/url-generator";
import Browser from "./components/pages/browser";
import About from "./components/pages/about";

import MessageBox from "./components/message-box";

import TimezoneContext from "./contexts/timezone";
import LocaleContext from "./contexts/locale";
import DebugContext from "./contexts/debug";

function App({URLSearchParams2}) {
    let localeParam = null, timezoneParam = null, installationOrGroupParam = null, installationOrGroupIdParam = null,
        tokenParam = null, updateLiveDataIntervalSecondsParam = null, updateRecentChartDataIntervalSecondsParam = null;

    if (URLSearchParams2.get('locale') !== null) {
        localeParam = decodeURIComponent(URLSearchParams2.get('locale'));
    }
    if (URLSearchParams2.get('timezone') !== null) {
        timezoneParam = decodeURIComponent(URLSearchParams2.get('timezone'));
    }
    if (URLSearchParams2.get('what') !== null) {
        installationOrGroupParam = decodeURIComponent(URLSearchParams2.get('what'));
    }
    if (URLSearchParams2.get('what-id') !== null) {
        installationOrGroupIdParam = parseInt(decodeURIComponent(URLSearchParams2.get('what-id')));
    }
    if (URLSearchParams2.get('t1') !== null) {
        updateLiveDataIntervalSecondsParam = parseInt(decodeURIComponent(URLSearchParams2.get('t1')));
    }
    if (URLSearchParams2.get('t2') !== null) {
        updateRecentChartDataIntervalSecondsParam = parseInt(decodeURIComponent(URLSearchParams2.get('t2')));
    }
    if (URLSearchParams2.get('token') !== null) {
        tokenParam = decodeURIComponent(URLSearchParams2.get('token'));
    }

    const [locale, setLocale] = useState(localeParam ?? 'nl-NL');
    const [timezone, setTimezone] = useState(timezoneParam);
    const [installationOrGroup] = useState(installationOrGroupParam);
    const [installationOrGroupId] = useState(installationOrGroupIdParam);
    const [updateLiveDataIntervalSeconds] = useState(updateLiveDataIntervalSecondsParam);
    const [updateRecentChartDataIntervalSeconds] = useState(updateRecentChartDataIntervalSecondsParam);
    const [token] = useState(tokenParam);

    let messageBox;

    let greenLight = false;
    if (localeParam === null || installationOrGroup === null || installationOrGroupId === null || token === null ||
        updateLiveDataIntervalSeconds === null || updateRecentChartDataIntervalSeconds === null
    ) {
        const errors = [];
        if (localeParam === null) {
            errors.push('GET parameter "locale" missing');
        }
        if (timezoneParam === null) {
            errors.push('GET parameter "timezone" missing');
        }
        if (installationOrGroupParam === null) {
            errors.push('GET parameter "what" missing');
        }
        if (installationOrGroupIdParam === null) {
            errors.push('GET parameter "what-id" missing');
        }
        if (updateLiveDataIntervalSecondsParam === null) {
            errors.push('GET parameter "t1" missing');
        }
        if (updateRecentChartDataIntervalSecondsParam === null) {
            errors.push('GET parameter "t2" missing');
        }

        messageBox = <MessageBox errorMessages={errors} />
    } else {
        greenLight = true;
    }

    const matomoTrackerInstance = createInstance({
        urlBase: 'https://stats.lucus.nl',
        siteId: 26,
        heartBeat: {
            active: true,
            seconds: 3600
        },
        linkTracking: true
    });

    let location = useLocation();
    useEffect(() => {
        const baseUrl = window.location.protocol + '//' + window.location.host;
        let trackUrl = baseUrl + location.pathname;
        if (location.search !== '') {
            const params = new URLSearchParams(location.search);
            if (params.get('token') !== null) {
                params.set('token', params.get('token').substr(0, 4)); // for security reasons we strip the token to only the first 4 characters
            }
            trackUrl += '?' + params.toString();
        }
        matomoTrackerInstance.trackPageView({
            href: trackUrl
        });
    }, [location]);

    return (
        <MatomoProvider value={matomoTrackerInstance}>
            <DebugContext.Provider value={false}>
                <TimezoneContext.Provider value={[timezone, setTimezone]}>
                    <LocaleContext.Provider value={{locale, setLocale}}>
                        <div className="app">
                            <Header />
                            <Switch>
                                <Route path="/" exact render={(props) => (
                                    <div>
                                        {messageBox}
                                        <Screen {...props}
                                            greenLight={greenLight}
                                            token={token}
                                            installationOrGroup={installationOrGroup}
                                            installationOrGroupId={installationOrGroupId}
                                            liveDataUpdateIntervalSeconds={updateLiveDataIntervalSeconds}
                                        />
                                    </div>
                                )} />
                                {/*https://ui.dev/react-router-v4-pass-props-to-components/*/}
                                <Route path="/url-generator" exact render={(props) => (
                                    <UrlGenerator {...props}
                                              installationOrGroupParam={installationOrGroupParam}
                                              installationOrGroupIdParam={installationOrGroupIdParam}
                                              liveDataUpdateIntervalSecondsParam={updateLiveDataIntervalSecondsParam}
                                              recentChartUpdateIntervalSecondsParam={updateRecentChartDataIntervalSecondsParam}
                                              tokenParam={tokenParam}
                                    />
                                )} />
                                <Route path="/browser" exact component={Browser} />
                                <Route path="/about" exact component={About} />
                            </Switch>
                        </div>
                    </LocaleContext.Provider>
                </TimezoneContext.Provider>
            </DebugContext.Provider>
        </MatomoProvider>
    );
}

export default App;
