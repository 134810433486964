import "./styles.scss";

export default function MessageBox({notificationMessages = [], errorMessages = [], successMessages = []}) {
    return (
        <div className="lc-component message-box">
            {errorMessages.length > 0 &&
                <ul className="messages errors">
                    {errorMessages.map((message, key) =>
                        <Message key={key} message={message}/>
                    )}
                </ul>
            }
        </div>
    )
}

function Message({message = ''}) {
    return (
        <li>{message}</li>
    )
}
