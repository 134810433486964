import "./styles.scss";

export default function Overlay({spinContent = false, visible = false, children}) {
    return (
        <div className={'lc-component overlay' + (visible ? ' show' : '')}>
            <div className={'box' + (spinContent ? ' spin-content' : '')}>
                {children}
            </div>
        </div>
    )
}
