import {Unauthorized as UnauthorizedError} from "./errors/unauthorized.js";
import {ServerError} from "./errors/server-error.js";
import {Timeout as TimeoutError} from "./errors/timeout.js";
import {Generic as GenericError} from "./errors/generic.js";

export class ApiInterface {
    #apiUrl = 'https://api.mybestwatt.com';

    #defaultHeaders = {
        'x-User-Agent': 'screen.bestwatt.app version 0.1', // Request header field x-user-agent is not allowed by Access-Control-Allow-Headers in preflight response.
        'Content-Type': 'application/json'
    };

    /**
     * Damn, the inconsistency from the API is real :(
     *
     * Example /Session/Assets response:
     *
     * {
          "Result": [
            {
              "Result": "Ok"
            }
          ],
          "InstallationGroup": [
            {
              "ID": 1441,
              "DisplayName": "PV instalatie Rengineers"
            },
            ...
     *
     * Example /Session/Enumerations response:
     *
     * {
          "Result": [
            {
              "Code": "ClassificationCompactList",
              "DisplayName": "Classification"
            },
            ...
     *
     * Errors return http 200 status code as well :'(
     * https://www.youtube.com/watch?v=nSKp2StlS6s
     *
     * Todo maybe add cancel request support in the future?
     * @returns {Promise}
     */
    async makeRequest({token, path = '/', method = 'GET', headers = {}, timeoutMilliseconds = 10000}) {
        // we catch errors here to detect timeout errors and throw the responding error object
        // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
        try {
            const theHeaders = Object.assign({token: token}, this.#defaultHeaders, headers);
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), timeoutMilliseconds);
            let response = await fetch(this.#apiUrl + path, {
                signal: controller.signal,
                method: method,
                body: null,
                headers: theHeaders
            });
            clearTimeout(timeoutId);

            if (response.status >= 500 && response.status <= 599) {
                throw new ServerError(); // server errors a client cannot fix
            } else {
                if (response.status === 401) {
                    throw new UnauthorizedError();
                } else if (response.status !== 200) { // any other error
                    throw new GenericError('Error, API returend status code ' + response.status);
                }
            }

            return await response.json();
        } catch (error) {
            if (error instanceof DOMException && error?.name === 'AbortError') {
                throw new TimeoutError();
            } else {
                throw error; // rethrow
            }
        }
    }
}
