import './styles.scss';

import {useState, useRef} from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
    const hideAfterMillisecondsNoAction = 5000; // idle time before we hide the header

    const rootElement = useRef(null);
    let [isVisible, setIsVisible] = useState(false);
    let hideTimeoutId = null;

    hideTimeoutId = window.setTimeout(() => {
        hide();
    }, hideAfterMillisecondsNoAction);

    window.addEventListener('keyup', () => {
        interactionHandler();
    });

    window.addEventListener('mousemove', () => {
        interactionHandler();
    });

    function interactionHandler() {
        if (!isVisible) {
            setIsVisible(true);
        }
        if (hideTimeoutId !== null) {
            window.clearTimeout(hideTimeoutId);
        }
        hideTimeoutId = window.setTimeout(() => {
            hide();
        }, hideAfterMillisecondsNoAction);
    }

    function hide() {
        if (rootElement.current !== null) {
            rootElement.current.classList.add('animate-out');
            window.setTimeout(() => {
                rootElement.current.classList.remove('animate-out');
                setIsVisible(false);
            }, 1000);
        }
    }

    return (
        <header ref={rootElement} className={'lc-component header' + (isVisible ? ' visible' : ' hidden') }>
            <nav>
                <NavLink exact activeClassName="active" to="/">Home</NavLink>
                <NavLink exact activeClassName="active" to="/url-generator">URL generator</NavLink>
                <NavLink exact activeClassName="active" to="/browser">Browser capabilities check</NavLink>
                <NavLink exact activeClassName="active" to="/about">About</NavLink>
            </nav>
        </header>
    );
}

export default Header;
