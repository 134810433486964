import PageWrapper from "../../page-wrapper";

export default function About() {
    return (
        <PageWrapper padding={true} centreContentVertical={true}>
            <div className="lc-page about">
                <p>
                    Developer: Edwin Bos (<a style={{color: 'inherit'}} href="mailto:edwin@lucus.nl">edwin@lucus.nl</a>)<br />
                    Version: 0.9<br />
                    Released: 23-03-2021
                </p>
            </div>
        </PageWrapper>
    );
}